import React from "react";
import { Typography } from "@material-ui/core";
import NewsItem from "./NewsItem";
import { parseISO } from "date-fns";

export default ({ classes }) => {
  return [
    <NewsItem first time={parseISO("2024-09-13T08:42:00")} classes={classes}>
      <Typography gutterBottom>Epoch 508 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>73%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>118%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>16th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>89%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-08T18:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 507 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Friday were from the previous epoch. Our luck was{" "}
            <b>138%</b>. This epoch represents an annualised return of{" "}
            <b>3.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>73%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>11th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>125%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>1.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-09-03T20:19:00")} classes={classes}>
      <Typography gutterBottom>Epoch 506 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>51%</b>. This epoch represents an annualised return of{" "}
            <b>1.3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>138%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>6th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>73%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>1.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>6th September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-29T19:37:00")} classes={classes}>
      <Typography gutterBottom>Epoch 505 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>80%</b>. This epoch represents an annualised return of{" "}
            <b>2.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>51%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>1st September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>138%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>1st September</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-23T16:06:00")} classes={classes}>
      <Typography gutterBottom>Epoch 504 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>80%</b>. Less than predicted
            as we lost 2 slot battles, this was unavoidable. This epoch’s
            rewards will be on the <b>27th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>51%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-18T18:01:00")} classes={classes}>
      <Typography gutterBottom>Epoch 503 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>66%</b>. Slighty less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>22nd August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>95%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-08T23:00:00")} classes={classes}>
      <Typography gutterBottom>Epoch 502 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>86%</b>. This epoch represents an annualised return of <b>2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>66%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>17th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>73%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>256%</b>. This epoch represents an annualised return of{" "}
            <b>1.3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,

    <NewsItem first time={parseISO("2024-08-08T23:00:00")} classes={classes}>
      <Typography gutterBottom>Epoch 501 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>74%</b>. This epoch represents an annualised return of <b>2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>86%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>12th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>66%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>256%</b>. This epoch represents an annualised return of{" "}
            <b>1.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>256%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>12th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-08-04T20:01:00")} classes={classes}>
      <Typography gutterBottom>Epoch 500 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>111%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>74%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>7th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>88%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>256%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>7th August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>256%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-30T11:43:00")} classes={classes}>
      <Typography gutterBottom>Epoch 499 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>118%</b>. This epoch represents an annualised return of{" "}
            <b>3.3%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>111%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>2nd August</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>73%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>256%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-24T20:38:00")} classes={classes}>
      <Typography gutterBottom>Epoch 498 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>139%</b>. This epoch represents an annualised return of{" "}
            <b>3.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>118%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>28th July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>118%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-07-19T21:52:00")} classes={classes}>
      <Typography gutterBottom>Epoch 497 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>81%</b>. This epoch represents an annualised return of{" "}
            <b>2.2%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>139%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>23rd July</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>118%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
  ];
};
